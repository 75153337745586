import React from "react";
import "./HomeThreeContent.css";
// import { FaQuoteLeft } from "react-icons/fa";
// import buff from '../../../assests/Buffert.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Pagination, Autoplay } from "swiper/modules";
// import img1 from '../../../assests/img1.jpg'
import img1 from "../../../assests/1.jpg";
import img2 from "../../../assests/2.jpg";
import img3 from "../../../assests/3.jpg";
import img4 from "../../../assests/4.jpg";
import img5 from "../../../assests/5.jpg";
import img6 from "../../../assests/6.jpg";
import img7 from "../../../assests/7.jpg";
import img8 from "../../../assests/8.jpg";
import img9 from "../../../assests/9.jpg";
import img10 from "../../../assests/10.jpg";
import img11 from "../../../assests/11.jpg";
import img12 from "../../../assests/12.jpg";
import img13 from "../../../assests/13.jpg";
import img14 from "../../../assests/14.jpg";
import img15 from "../../../assests/15.jpg";
import Heading from "../Heading/Heading";

const HomeThreeContent = () => {
  return (
    <div>
      <div className="wholethree">
        <div className="subthree">
          <div className="marginClass">
            {/* <h2>MENTAL HEALTH MATTERS</h2>
            <p>
              "Famous voices speak out on why mental health matters, inspiring
              us all to take care of our minds. Let’s make mental well-being a
              priority for everyone."
            </p> */}
            <Heading
              headClass="heading txtColor"
              Head="MENTAL HEALTH MATTERS"
              desc="“Famous voices speak out on why mental health matters, inspiringus all to take care of our minds. Let’s make mental well-being apriority for everyone.”"
            />
          </div>
          <Swiper
            // slidesPerView={3.3}
            slidesPerView={4}
            spaceBetween={30}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            loop={true}
            autoplay={{
              delay: 2500,
            }}
            // breakpoints={
            //   {
            //     650:{
            //       slidesPerView: 2.5,
            //       spaceBetween:20
            //     },
            //     600:{
            //       slidesPerView: 2,
            //       spaceBetween:20
            //     }
            //   }
            // }
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {/* <div className='griddiv'> */}
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img1} alt="" />
                {/* <FaQuoteLeft className='quote' />
                <p>You didn't quit skateboarding because you got old, you got old because you quit skateboarding.</p>
                <div className='persondetails'>
                    <img src={buff} alt="" />
                    <div className='personname'>
                      <p>Warren Buffett</p>
                      <span>Berkshire Hathaway</span>
                    </div> */}
                {/* </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img2} alt="" />
                {/* <FaQuoteLeft className='quote' />
                <p>You didn't quit skateboarding because you got old, you got old because you quit skateboarding.</p>
                <div className='persondetails'>
                    <img src={buff} alt="" />
                    <div className='personname1'>
                      <p>Warren Buffett</p>
                      <span>Berkshire Hathaway</span>
                    </div>
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img3} alt="" />
                {/* <FaQuoteLeft className='quote' />
                <p>You didn't quit skateboarding because you got old, you got old because you quit skateboarding.</p>
                <div className='persondetails'>
                    <img src={buff} alt="" />
                    <div className='personname2'>
                      <p>Warren Buffett</p>
                      <span>Berkshire Hathaway</span>
                    </div>
                </div> */}
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img4} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img5} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img6} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img7} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img8} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img9} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img10} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img11} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img12} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img13} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img14} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div className="carddesign">
                <img src={img15} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeThreeContent;
