/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./NavBar.css";
import logo from "../../assests/logo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { IoMdClose } from "react-icons/io";
import { MdOutlineCalendarToday } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { GoHomeFill } from "react-icons/go";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdEditCalendar } from "react-icons/md";
import { MdConnectWithoutContact } from "react-icons/md";
import axios from "axios";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
// import "animate.css";

const NavBar = () => {
  const dispatch = useDispatch();
  const [isSelect, setSelect] = useState("home");
  const navHandler = (e) => {
    setSelect(e.target.id);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [formdata, setformdata] = useState({
    name: "",
    gender: "",
    dob: "",
    phone: "",
    email:"",
    lang: "",
    available: "",
    timing: "",
    issues: "",
    concern: "",
  });
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const submitbtn = async () => {
    setloading(true);
    setstatus(false);
    var newobj = {
      name: formdata.name,
      gender: formdata.gender,
      dob: formdata.dob,
      phone: formdata.phone,
      email: formdata.email,
      available: formdata.available,
      timing: formdata.timing,
      lang: formdata.lang,
      issues: formdata.issues,
      concern: formdata.concern,
    };
    axios
      .post("https://api.trancegroups.com/sendEmail", newobj)
      .then((response) => {
        setloading(false);
        setstatus(true);
        setTimeout(() => {
          setstatus(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });

    setformdata({
      name: "",
      gender: "",
      dob: "",
      phone: "",
      email: "",
      lang: "",
      available: "",
      timing: "",
      issues:"",
      concern: "",
    });
  };
  return (
    <div>
      <div className="webHandler">
        <div className="navBar">
          <img src={logo} alt="" />
          <div className="menu">
            <div className="menuItems">
              {isSelect == "home" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "home" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="home"
              >
                Home
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "about" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "about" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="about"
              >
                About Us
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "blog" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "blog" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="blog"
              >
                Blogs
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "contact" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "contact" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="contact"
              >
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="navBarMob mobileHandler sectionWidth">
        <div className="navBarMobBody">
          <img src={logo} alt="" />
          <HiOutlineMenuAlt3
            id="mobNav"
            onClick={ScheduleCallHandler}
            className="navMobLogo"
          />
        </div>
      </div>
      {isPopUp == "call" && (
        <>
          <div className="scheduleOverlay">
            <div className="scheduleOverlayHead">
              <div className="scheduleOverlayHeadLeft">
                <MdOutlineCalendarToday className="IconClose" />
                <h1>Request a Call !</h1>
              </div>
              <div className="scheduleOverlayHeadRight">
                <IoMdClose onClick={closeOverlay} className="IconClose" />
              </div>
            </div>
            <div className="scheduleOverlayBody">
              <img src={logo} alt="" />
              <div className="scheduleOverlayBodyContent">
              <h2>Full Name <span style={{ color: "red" }}>*</span></h2>
              <input
                  type="text"
                  required
                  name="name"
                  onChange={handleChange}
                  defaultValue={formdata.name}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Gender <span style={{ color: "red" }}>*</span></h2>
                <select
                  name="gender"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.gender}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>DOB <span style={{ color: "red" }}>*</span></h2>
                <input
                  type="date"
                  name="dob"
                  onChange={handleChange}
                  defaultValue={formdata.dob}
                  required
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Phone Number <span style={{ color: "red" }}>*</span></h2>
                <input
                  type="text"
                  name="phone"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.phone}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Email <span style={{ color: "red" }}>*</span></h2>
                <input
                  type="text"
                  name="email"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.email}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Preferred language <span style={{ color: "red" }}>*</span></h2>
                <select
                  name="lang"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.lang}
                >
                  <option value="">Select</option>
                  <option value="Tamil">Tamil</option>
                  <option value="English">English</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>When is the most convenient time for you ? <span style={{ color: "red" }}>*</span></h2>
                <select
                  name="available"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.available}
                >
                  <option value="">Select</option>
                  <option value="week days">week days</option>
                  <option value="weekend">weekend</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Preferred timing - your preferred call time <span style={{ color: "red" }}>*</span></h2>
                <select
                  name="timing"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.timing}
                >
                  <option value="">Select</option>
                  <option
                    value="morning 9Am - 12Pm"
                  >
                    Morning 9Am - 12Pm
                  </option>
                  <option value="Afternoon 1Pm - 5Pm">Afternoon 1Pm - 5Pm</option>
                  <option value="Evening 5Pm to 10Pm">Evening 5Pm to 10Pm</option>
                  {/* <option value="morning 4Am - 8Pm">morning 4Am - 8Pm</option>
                  <option value="morning 8Am - 10Pm">morning 8Am - 10Pm</option> */}
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>What issues would you like to address? <span style={{ color: "red" }}>*</span></h2>
                <select
                  name="issues"
                  required
                  onChange={handleChange}
                  defaultValue={formdata.issues}
                >
                  <option value="">Select</option>
                  <option value="Stress">Stress</option>
                  <option value="Anxiety">Anxiety</option>
                  <option value="Depression">Depression</option>
                  <option value="Relationship Issues">
                    Relationship Issues
                  </option>
                  <option value="Family Difficulties">
                    Family Difficulties
                  </option>
                  <option value="Sleep problems">Sleep problems</option>
                  <option value="Family Therapy" className="highlight">
                    Family Therapy
                  </option>
                  <option value="Couples Therapy" className="highlight">
                    Couples Therapy
                  </option>
                  <option value="Individual Therapy" className="highlight">
                    Individual Therapy
                  </option>
                  <option value="marital and pre-marital counseling">
                    marital and pre-marital counseling
                  </option>
                  <option value="Overthinking">Overthinking</option>
                  <option value="Loneliness">Loneliness</option>
                  <option value="Fear of Failure">Fear of Failure</option>
                  <option value="Lack of Concentration">
                    Lack of Concentration
                  </option>
                  <option value="Career-Related Concerns">
                    Career-Related Concerns
                  </option>
                  <option value="Trauma">Trauma</option>
                  <option value="Grief and Loss">Grief and Loss</option>
                  <option value="Anger Management">Anger Management</option>
                  <option value="Substance Abuse">Substance Abuse</option>
                  <option value="Not Listed"> Not Listed</option>
                  <option value="I Don't Know"> I Don't Know</option>
                </select>
              </div>
              {/* <div className="scheduleOverlayBodyContent">
                <h2>What’s your problem ?</h2>
                <textarea
                  name="problem"
                  onChange={handleChange}
                  defaultValue={formdata.problem}
                  cols="30"
                  rows="4"
                ></textarea>
              </div> */}
              <div className="scheduleOverlayBodyContent">
                <h2>We would be love to learn more about your concerns.</h2>
                <textarea
                  name="concern"
                  onChange={handleChange}
                  defaultValue={formdata.concern}
                  optional
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              {status === true ? (
                <p className="statustext">
                  Your call has been successfully scheduled.
                </p>
              ) : null}

              {loading === false ? (
                <button onClick={submitbtn}>Request a Call !</button>
              ) : (
                <button>Please Wait....</button>
              )}

              <div className="NavIcons">
                {/* <div className="SocialMob">
                  <a href="tel:+917868801278">
                    <IoMdCall />
                  </a>
                  <a href="https://wa.me/917868801278?text=How can i help you ?">
                    <FaWhatsapp />
                  </a>
                  <Link to="https://www.facebook.com/profile.php?id=61555821675045">
                    <FaFacebookF />
                  </Link>
                  <Link to="https://youtube.com/@trance-admin?si=ym6i2BE8zBLGi0sF">
                    <FaYoutube />
                  </Link>
                  <Link to="https://www.linkedin.com/company/trance-groups/">
                    <FaLinkedinIn />
                  </Link>
                  <Link to="https://www.instagram.com/the._.trance_/?utm_source=ig_web_button_share_sheet">
                    <FaInstagram />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUp == "call" && <div onClick={closeOverlay} id="overlay"></div>}
      {/* {isPopUp == "mobNav" && ( */}
      <>
        <div
          id="mobNav1"
          className={isPopUp == "mobNav" ? "mobNavbarActive" : "mobNavbar"}
        >
          <div className="mobNavbarTop">
            <div className="mobNavbarImg">
              <img src={logo} alt="" />
              <IoMdCloseCircle
                onClick={ScheduleCallHandler}
                className="mobNavbarIcon"
              />
            </div>
            <div className="mobNavbarMenu">
              <h5
                id="home"
                onClick={navHandler}
                className={
                  isSelect == "home"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <GoHomeFill className="mobMenuIcon" />
                Home
              </h5>
              <h5
                id="about"
                onClick={navHandler}
                className={
                  isSelect == "about"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <BsFillGrid1X2Fill className="mobMenuIcon" />
                About
              </h5>
              <h5
                id="blog"
                onClick={navHandler}
                className={
                  isSelect == "blog"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <MdEditCalendar className="mobMenuIcon" />
                Blog
              </h5>
              <h5
                id="contact"
                onClick={navHandler}
                className={
                  isSelect == "contact"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <MdConnectWithoutContact className="mobMenuIcon" />
                Contact Us
              </h5>
            </div>
          </div>
          <div className="mobNavbarBottom">
            <p>
              "To schedule a call, fill out this form, send a WhatsApp message,
              or give a missed call."
            </p>
            <div className="btnandIcon">
              <button id="call" onClick={ScheduleCallHandler} className="">
                Request a Call
              </button>
              <a href="tel:+917868801278">
                <IoMdCall />
              </a>
              <a href="https://wa.me/917868801278?text=How can i help you ?">
                <FaWhatsapp />
              </a>
            </div>
            <div className="NavIcons">
              {/* <div className="SocialMob"> */}
              {/* <a href="tel:+917868801278">
                  <IoMdCall />
                </a>
                <a href="https://wa.me/917868801278?text=How can i help you ?">
                  <FaWhatsapp />
                </a> */}
              <Link to="https://www.facebook.com/profile.php?id=61555821675045">
                <FaFacebookF />
              </Link>
              <Link to="https://youtube.com/@trance-admin?si=ym6i2BE8zBLGi0sF">
                <FaYoutube />
              </Link>
              <Link to="https://www.linkedin.com/company/trance-groups/">
                <FaLinkedinIn />
              </Link>
              <Link to="https://www.instagram.com/the._.trance_/?utm_source=ig_web_button_share_sheet">
                <FaInstagram />
              </Link>
              {/* </div> */}
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default NavBar;
