import React, { useEffect } from "react";
import "./Testimonial.css";
import Heading from "../../Reusable/Heading/Heading";
import family from "../../../assests/bentoGrid.png";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialCard from "../../Reusable/TestimonialCard/TestimonialCard";

const TestimonialComp = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="marginTop50 sectionWidth">
        <Heading
          headClass="heading txtColor"
          Head="Testimonials"
          desc="Real stories from those who found healing through our services. Hear how their lives transformed with the right support."
        />
        <TestimonialCard
          head="Anonymous"
          desc="I came to Trance on my partner's recommendation to manage my anger and work stress, which were affecting our relationship. The therapist listened, analyzed my issues, and provided effective techniques to help me relax and balance work and personal life. Their approach was professional, supportive, and affordable."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Anonymous"
          desc="I took 4 session at Trance, the therapist was very friendly and easy to approach anytime, i found a positive experience and effective changes in my anxiety issues. I highly recommend their services."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
        <TestimonialCard
          head="Anonymous"
          desc="Trance has helped me in sorting my relationship issues, they helped me in unlocking my thoughts and making proper decisions about my relationship and helped me with improving my mental health with positive outcomes. I will be forever grateful to trance for making me strong and positive person."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Anonymous"
          desc="I struggled with sleep issues that affected my daily life, but after a few therapy sessions at Trance, I saw positive improvements. Their guidance helped me regain my sleep and confidence. I highly recommend Trance for anyone facing similar problems."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
        <TestimonialCard
          head="Anonymous"
          desc="I approached Trance with a heavy heart, struggling with negative thoughts and low self-confidence due to past trauma. Thanks to their patient and supportive approach, therapy, and helpful activities, I now feel more confident, strong, and energetic. Trance truly transformed my life, and I highly recommend their services to anyone facing challenges."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Anonymous"
          desc="I reached out to Trance during a tough time after losing someone close to me. Through nine therapy sessions, they helped me accept my loss, regain my strength, and rebuild my life. Initially unsure about therapy, they patiently explained the process and made me comfortable. I’m grateful for the transformation and would confidently recommend Trance to anyone going through a difficult time."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
      </div>
    </div>
  );
};

export default TestimonialComp;
