/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../../HomeComp/HomeBanner/HomeBanner.css";
import video from "../../../assests/trance intro final.mp4";
import How from "../../../assests/how.png";
import assuranceImg from "../../../assests/assuranceshade.png";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { FaWpforms } from "react-icons/fa";
import { FaPlayCircle } from "react-icons/fa";
import { FaPauseCircle } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const HomeMobileBanner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
  }, []);
  // const [isPlay, setIsPlay] = useState(false);
  // const videoHandler = (e) => {
  //   const vid = document.getElementById("MobBannerVideo1");
  //   vid.play();
  //   console.log(vid);
  //   setIsPlay(!isPlay);
  // };
  // const videoHandlerPause = (e) => {
  //   const vid = document.getElementById(e);
  //   vid.pause();
  //   console.log(vid);
  //   setIsPlay(!isPlay);
  // };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  return (
    <div className="hidden">
      <div className="homeMobileBanner sectionWidth">
        <div className="homeMobileBannerHead">
          <h1>
            Welcome to Trance<br></br>
          </h1>
          <h2 className="headSpan">
            Your premier online Mental Health Service provider."
          </h2>
        </div>
        <div className="homeMobileBannerVedio">
          <div className="homeMobileBannerVedioConatiner">
            <div className="homeMobileBannerVedioSection">
              {/* {!isPlay && <div id="overlayHero"></div>} */}
              <video
                id="MobBannerVideo1"
                width="320"
                height="240"
                controls={true}
                playsInline={false}
              >
                <source src={video} type="video/mp4"></source>
              </video>
              {/* <img className="HowItWorks" src={How} alt="" /> */}
            </div>
            {/* <div className="pause">
              {isPlay == true ? (
                <FaPauseCircle
                  onClick={() => videoHandlerPause("MobBannerVideo1")}
                />
              ) : (
                <div>
                  <FaPlayCircle
                    onClick={() => videoHandler("MobBannerVideo1")}
                  />
                </div>
              )}
            </div> */}

            {/* <div className="SocialMob">
              <a href="tel:+917868801278">
                <IoMdCall />
              </a>
              <a href="https://wa.me/917868801278?text=How can i help you ?">
                <FaWhatsapp />
              </a>
              <Link to="https://www.facebook.com/profile.php?id=61555821675045">
                <FaFacebookF />
              </Link>
              <Link to="https://youtube.com/@trance-admin?si=ym6i2BE8zBLGi0sF">
                <FaYoutube />
              </Link>
              <Link to="https://www.linkedin.com/company/trance-groups/">
                <FaLinkedinIn />
              </Link>
              <Link to="https://www.instagram.com/the._.trance_/?utm_source=ig_web_button_share_sheet">
                <FaInstagram />
              </Link>
            </div> */}
          </div>
          {/* <div className="homeBannerButton">
            <button
              id="call"
              onClick={ScheduleCallHandler}
              className="callButton"
            >
              Schedule Call
            </button>
            <a href="https://wa.me/917868801278?text=How can i help you ?">
              <FaWhatsapp className="whatsAppIconBanner" />
            </a>
            <a href="tel:+917868801278">
              <IoMdCall className="callIconBanner" />
            </a>
            <FaWpforms
              id="call"
              className="callIconBanner"
              onClick={ScheduleCallHandler}
            />
          </div> */}
          <p>
            To help, To heal, To educate people, the ultimate challenge We are
            devoted to promoting mental wellbeing through online counseling,
            psychoeducation and community support, therapies. Our team of
            certified psychologists is dedicated to creating a safe space for
            healing and personal growth. Explore our resources, join our
            programs, and embark on a journey towards mental wellness with us.
            Together, let’s break down barriers and a resilient and thriving
            community.
          </p>
        </div>
        <div className="assurance">
          <div data-aos="fade-up" className="assuranceContent">
            <h2>Privacy Assurance</h2>
            <p>privacy isn’t just a promise, it's our commitment .</p>
          </div>
          <div data-aos="fade-up" className="assuranceContent">
            <h2>Timely therapy</h2>
            <p>
              Counseling is available whenever it is convenient for you; select
              a time that works for you
            </p>
          </div>
          <div data-aos="fade-up" className="assuranceContent">
            <h2>Flexible price</h2>
            <p>Adaptable pricing designed to suit your budget and need</p>
          </div>
          <div data-aos="fade-up" className="assuranceContent">
            <h2>Experienced therapist</h2>
            <p>
              experienced therapist prepared to guide you on the road to success
            </p>
          </div>
          <img src={assuranceImg} alt="" />
        </div>
        {/* <div className="certificate">
          <h2>We are certified & recognised by</h2>
          <div className="certificateImg">
            <img
              src="https://www.themoodspace.com/Assets/LandingPage/ISO27001CompliantLogo.png"
              alt=""
            />
            <img
              src="https://www.themoodspace.com/Assets/LandingPage/HippaCompliantLogo.png"
              alt=""
            />
            <img
              src="https://www.themoodspace.com/Assets/LandingPage/GDPR-logo.png"
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomeMobileBanner;
