import React from "react";
import "./TestimonialCard.css";
import { FaStar } from "react-icons/fa";

const TestimonialCard = (props) => {
  return (
    <div>
      <div data-aos="fade-up" className={props.Body}>
        <h1>Anonymous</h1>
        <p>{props.desc}</p>
        <div className="bentGrid2Icon">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </div>
        <div className="testimonialImgSection">
          <img className={props.imageClass} src={props.image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
