/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./App.css";
import Routing from "./Routing/Routing";
import Store from "./Store/Store";
import { storeAction } from "./Store/Store";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";

function App() {
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const [isCall, setIsCall] = useState(false);
  const CallHandler = () => {
    setIsCall(!isCall);
  };

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander(""));
  };
  return (
    <div className="App">
      {isPopUp == "call" ||
        (isPopUp == "mobNav" && (
          <div onClick={closeOverlay} id="overlay"></div>
        ))}
      {isPopUp != "call" && (
        <div className="requestCall">
          <h1 onClick={CallHandler} className="requestCallText">
            Request a Call Back
          </h1>
          {isCall && (
            <div className="overlayIcon">
              <FaWpforms
                id="call"
                className="formIcon"
                onClick={ScheduleCallHandler}
              />
              <a href="tel:+917868801278">
                <IoMdCall className="callIcon" />
              </a>
              <a
                href="https://wa.me/917868801278?text=How can i help you ?"
                target="_blank"
              >
                <FaWhatsapp className="whatsAppIcon" />
              </a>
            </div>
          )}
        </div>
      )}
      <Routing />
    </div>
  );
}

export default App;
